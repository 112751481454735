<template>
    <div ref="scrollTarget"></div>

    <MainNav />
    <section style="padding-top: 65px;">
        <section>
            <header class="header-container-laptop position-relative laptop-section">
                <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="3000">
                            <img src="../assets/img/coworking_header.jpg" class="d-block w-100" alt="Image 3">
                        </div>
                    </div>
                </div>
                <div class="position-absolute top-50 start-50">
                    <div class="row gx-5" style="margin-top: -65px;">
                        <div class="col-md-8">
                            <p style="color: white; font-size: 1.7em; font-weight: 600;">{{
                                translate('coworking_header') }}</p>
                            <p style="color: white; font-size: large; margin-top: 10px;">{{
                                translate('coworking_sheader') }}</p>
                            <br>
                            <a href="https://eurekali-point.com/signup-coworking" style="color: #E5097F; text-decoration: none;"><button
                                    class="btn btn-primary join-us-btn">
                                    <i style="font-size: 20px;" class="fa fa-user-plus" aria-hidden="true"></i> {{
                                translate('join_us') }}</button></a>
                        </div>
                    </div>
                </div>
            </header>

            <!-- Mobile -->
            <header class="header-container-laptop position-relative mobile-section">
                <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="3000">
                            <img src="../assets/img/coworking_header_mobile.jpg" class="d-block w-100" alt="Image 3">
                        </div>
                    </div>
                </div>
            </header>
            <div class="mobile-section"
                style="background-color: #E5097F; text-align: center; padding-left: 15px; padding-right: 15px; padding-bottom: 35px;">
                <p style="color: white; font-size: large; font-weight: 600;">{{ translate('coworking_header') }}</p>
                <p style="color: white; font-size: medium;">{{ translate('coworking_sheader') }}</p>

                <button class="btn btn-primary join-us-btn"> <i style="font-size: 18px;" class="fa fa-user-plus"
                        aria-hidden="true"></i> <a href="https://eurekali-point.com/signup-coworking" style="color: #E5097F; text-decoration: none;"> {{
                                translate('join_us') }}</a></button>
            </div>

            <!-- Ipad -->
            <header class="header-container-laptop position-relative ipad-section">
                <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="3000">
                            <img src="../assets/img/coworking_header_ipad.jpg" class="d-block w-100" alt="Image 3">
                        </div>
                    </div>
                </div>
                <div class="position-absolute top-50 start-50">
                    <p style="color: white; font-size: x-large; font-weight: bold;">{{ translate('coworking_header') }}</p>
                    <p style="color: white; font-size: medium; font-weight: 400;">{{ translate('coworking_sheader') }}</p>
                    <button class="btn btn-primary join-us-btn" style="margin-left: 10px;"> <i style="font-size: 18px;"
                            class="fa fa-user-plus" aria-hidden="true"></i> <a
                            href="https://eurekali-point.com/signup-coworking"
                            style="color: #E5097F; text-decoration: none;">
                            {{ translate('join_us') }}</a></button>
                </div>
            </header>
        </section>


        <!-- Mobile -->
        <section class="container mt-md-5 mobile-section" style="margin-top: 30px;">
            <div class="container-fluid d-flex justify-content-center align-items-center">
                <div class="row align-items-center">
                    <div class="col-12 col-md-auto text-center" style="margin-top: 15px;">
                        <img src="../assets/img/question_icon_cow.svg" style="max-width: 70px;" alt="Description Icon"
                            class="img-fluid">
                    </div>
                    <div class="col-12 col-md" style="text-align: center; padding: 15px;">
                        <p style="color: #E5097F; font-weight: bold; font-size: 1.2em;">
                            {{ translate('coworking_section1_title') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Ipad -->
        <section class="container mt-md-5 ipad-section" style="margin-top: 30px;">
            <div class="container-fluid d-flex justify-content-center align-items-center">
                <div class="row align-items-center">
                    <div class="col-12 text-center text-md-center">
                        <img src="../assets/img/question_icon_cow.svg" style="max-width: 80px;" alt="Description Icon" class="img-fluid">
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <p style="color: #E5097F; font-weight: 600; font-size: 22px; margin-top: 12px;">
                            {{ translate('coworking_section1_title') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="container-fluid mt-md-5 laptop-section" style="margin-top: 200px;">
            <div class="container-fluid d-flex justify-content-center align-items-center">
                <div class="row align-items-center">
                    <div class="col-12 col-md-auto text-center text-md-left">
                        <img src="../assets/img/question_icon_cow.svg" alt="Description Icon" class="img-fluid">
                    </div>
                    <div class="col-12 col-md" style="text-align: left;">
                        <p style="color: #E5097F; font-weight: 600; font-size: 22px; width: 600px;">
                            {{ translate('coworking_section1_title') }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="laptop-section">
            <section style="margin-top: 40px; padding-top: 20px;">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-md-6">
                            <img src="../assets/img/coworking_qr.jpg">
                        </div>
                        <div class="col-md-6 d-flex justify-content-center align-items-center">
                            <div style="text-align: left; padding: 25px;">
                                <p style="color: #E5097F; font-weight: 600; font-size: 1.5em;">{{
                                translate('coworking_section1_par1_title') }}</p>
                                <p style="color: black; font-weight: 400; font-size: 1.1em; margin-top: 15px;">{{
                                translate('coworking_section1_par1_desc') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center text-center" style="margin-top: 50px;">
                        <div class="col-6">
                            <div style="background-color: #F3F3F3; padding: 30px; border-radius: 15px">
                                <div style="width: 70%;" class="mx-auto">
                                    <div class="d-flex align-items-center">
                                        <img src="../assets/img/deal_icon.svg" style="max-width: 70px;">
                                        <span
                                            style="color: #E5097F; font-weight: 600; font-size: 1.3em; text-align: left; margin-left: 20px">
                                            {{ translate('coworking_section1_par2_title') }}
                                        </span>
                                    </div>
                                </div>
                                <div class="mx-auto">
                                    <p style="text-align: left; color: black; font-size: medium; margin-top: 15px;">
                                        {{ translate('coworking_section1_par2_desc') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style="margin-top: 20px; padding-top: 10px; padding-bottom: 50px;">
                <div class="container">
                    <div class="row justify-content-center text-center" style="margin-top: 50px;">
                        <div class="col-6">
                            <div class="mx-auto">
                                <div class="d-flex align-items-center">
                                    <img src="../assets/img/advntage_icon.svg" style="max-width: 70px;">
                                    <span
                                        style="color: #E5097F; font-weight: 600; font-size: x-large; text-align: left; margin-left: 20px">
                                        {{ translate('coworking_section2_title') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid" style="padding-bottom: 60px;"
                    :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
                    <div class="container" style="margin-top: 30px;">
                        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-bs-interval="3000">
                                    <div class="row">
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn1_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn1_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn2_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn2_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn3_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn3_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn4_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn4_desc') }}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section class="mobile-section">
            <section style="margin-top: 20px; padding-top: 10px;">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-md-6">
                            <img src="../assets/img/coworking_qr.jpg">
                        </div>
                        <div class="col-md-6 d-flex justify-content-center align-items-center">
                            <div style="text-align: center; margin-top: 20px;">
                                <p style="color: #E5097F; font-weight: 600; font-size: 1.2em;">{{
                                translate('coworking_section1_par1_title') }}</p>
                                <p style="color: black; font-weight: 400; margin-top: 15px;">{{
                                translate('coworking_section1_par1_desc') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center text-center" style="margin-top: 50px;">
                        <div class="col-12">
                            <div style="background-color: #F3F3F3; padding: 20px; border-radius: 15px">
                                <div class="mx-auto">
                                        <img src="../assets/img/deal_icon.svg" style="max-width: 70px;">
                                        <br>
                                        <span
                                            style="color: #E5097F; font-weight: 600; font-size: large; text-align: center; margin-top: 15px;">
                                            {{ translate('coworking_section1_par2_title') }}
                                        </span>
                                </div>
                                <div class="mx-auto">
                                    <p style="text-align: center; color: black; font-size: medium; margin-top: 15px;">
                                        {{ translate('coworking_section1_par2_desc') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style="margin-top: 20px; padding-top: 10px; padding-bottom: 50px;">
                <div class="container">
                    <div class="row justify-content-center text-center" style="margin-top: 50px;">
                        <div class="col-12">
                            <div class="mx-auto">
                                    <img src="../assets/img/advntage_icon.svg" style="max-width: 70px;">
                                    <br>
                                    <span
                                        style="color: #E5097F; font-weight: 600; font-size: x-large; text-align: center; margin-top: 20px;">
                                        {{ translate('coworking_section2_title') }}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid" style="padding-bottom: 60px;"
                    :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
                    <div class="container" style="margin-top: 30px;">
                        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-bs-interval="3000">
                                    <div class="row">
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn1_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn1_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn2_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn2_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn3_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn3_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn4_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn4_desc') }}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <section class="ipad-section">
            <section style="margin-top: 20px; padding-top: 10px;">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-md-12">
                            <img src="../assets/img/coworking_qr.jpg" style="max-width: 600px;">
                        </div>
                        <div class="col-md-12 d-flex justify-content-center align-items-center">
                            <div style="text-align: center; margin-top: 20px;">
                                <p style="color: #E5097F; font-weight: 600; font-size: 1.2em;">{{
                                translate('coworking_section1_par1_title') }}</p>
                                <p style="color: black; font-weight: 400; margin-top: 15px;">{{
                                translate('coworking_section1_par1_desc') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center text-center" style="margin-top: 50px;">
                        <div class="col-12">
                            <div style="background-color: #F3F3F3; padding: 20px; border-radius: 15px">
                                <div class="mx-auto">
                                        <img src="../assets/img/deal_icon.svg" style="max-width: 70px;">
                                        <br>
                                        <span
                                            style="color: #E5097F; font-weight: 600; font-size: large; text-align: center; margin-top: 15px;">
                                            {{ translate('coworking_section1_par2_title') }}
                                        </span>
                                </div>
                                <div class="mx-auto">
                                    <p style="text-align: center; color: black; font-size: medium; margin-top: 15px;">
                                        {{ translate('coworking_section1_par2_desc') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style="margin-top: 20px; padding-top: 10px; padding-bottom: 50px;">
                <div class="container">
                    <div class="row justify-content-center text-center" style="margin-top: 50px;">
                        <div class="col-12">
                            <div class="mx-auto">
                                    <img src="../assets/img/advntage_icon.svg" style="max-width: 70px;">
                                    <br>
                                    <span
                                        style="color: #E5097F; font-weight: 600; font-size: x-large; text-align: center; margin-top: 20px;">
                                        {{ translate('coworking_section2_title') }}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid" style="padding-bottom: 60px;"
                    :style="{ backgroundImage: 'url(' + backgroundImage2 + ')', 'background-repeat': 'no-repeat', 'background-position': 'bottom', 'background-size': 'cover' }">
                    <div class="container" style="margin-top: 30px;">
                        <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-pause="false">
                            <div class="carousel-inner">
                                <div class="carousel-item active" data-bs-interval="3000">
                                    <div class="row">
                                        <div class="col-6" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn1_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn1_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn2_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn2_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn3_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn3_desc') }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6" style="margin-top: 15px;">
                                            <div class="border"
                                                style="text-align: center; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; border-radius: 10px; height: 100%;">
                                                <p style="font-weight: bold; font-size: 1.1em; color: black;">{{
                                translate('coworking_advn4_title') }}</p>
                                                <p
                                                    style="font-weight: 400; color: black; margin-top: 10px; font-size: medium;">
                                                    {{ translate('coworking_advn4_desc') }}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

    

        <section>
            <section class="container-fluid" style="margin-top: 50px; padding-top: 90px; padding-bottom: 90px;"
                :style="{ backgroundImage: 'url(' + backgroundImage1 + ')', 'background-position': 'center', 'background-size': 'cover' }">
                <div class="container d-flex justify-content-center align-items-center">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-md-auto text-center text-md-left">
                            <img src="../assets/img/logo-icon.svg" alt="Description Icon" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-7" style="text-align: left;">
                            <p style="color: #E5097F; font-weight: 600; font-size: 1.2em; margin-top: 30px;">
                                {{ translate('coworking_joinus_par1') }}<br>
                                {{ translate('coworking_joinus_par2') }}<br>
                                {{ translate('coworking_joinus_par3') }}
                            </p>

                            <button class="btn btn-read-more" style="margin-top: 30px;"><i style="font-size: 18px;"
                                    class="fa fa-user-plus" aria-hidden="true"></i> <a href="https://eurekali-point.com/signup-coworking"
                                    style="color: white; text-decoration: none;">{{ translate('join_us') }}</a></button>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </section>
</template>


<script>
import MainNav from '@/components/MainNav2.vue';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import BackgroundImage1 from '../assets/img/background-eurekali.png';
import BackgroundImage2 from '../assets/img/background_gray.jpg';


export default {
    mixins: [en, fr, nl],
    components: {
        MainNav
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundImage1: BackgroundImage1,
            backgroundImage2: BackgroundImage2
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    async mounted() {
        document.title = 'Coworking | Eurekali';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
    }
}
</script>


<style scoped>
.btn-login {
    color: white;
    background-color: #E5097F;
    font-weight: 400;
    border-color: #E5097F;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 158px;
    border: none;
    border-radius: 20px;
}

.btn-login:hover {
    color: white;
    background-color: #9B0656;
}

.btn-signup {
    color: black;
    background-color: white;
    font-weight: 600;
    border-color: white;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 128px;
    border: none;
    border-radius: 20px;
}


.btn-logout {
    color: white;
    background-color: #dc3545;
    font-weight: 600;
    border-color: #9B0656;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    border: none;
    border-radius: 20px;
}

.btn-logout:hover {
    color: white;
    background-color: rgb(175, 12, 12);
}



.header-container-laptop {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.header-image-laptop {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.service-card-laptop {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin: 15px 0;
    margin-top: 5px;
    padding: 25px;
    padding-bottom: 20px;
    cursor: pointer;
}

.service-card-laptop h5 {
    color: #E5097F;
    font-weight: bold;
    margin-top: 10px;
}

.service-card-laptop p {
    margin-top: 5px;
    text-align: center;
    color: black;
}

.join-us-btn {
    margin-top: 15px;
    background-color: white;
    color: #E5097F;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid white;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.join-us-btn:hover {
    background-color: #E5097F;
    border: 1px solid white;
    color: white;
}


.custom-input {
    border-radius: 20px;
    margin-right: 10px;
    width: 250px;
}

.custom-button {
    background-color: white;
    color: #E5097F;
    border-radius: 20px;
    font-weight: bold;
    border: 1px solid white;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-button:hover {
    background-color: #E5097F;
    border: 1px solid white;
    color: white;
}

.btn-read-more {
    background-color: #E5097F;
    color: white;
    border-radius: 20px;
    font-weight: 600;
}

.btn-read-more:hover {
    background-color: #9B0656;
    color: white;
}


.service-image {
    height: 350px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    position: relative;
    color: white;
}

.overlay-text {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
}

.mobile-section {
    display: none;
}

.ipad-section {
    display: none;
}

@media (max-width: 767px) {
    .mobile-section {
        display: block;
    }

    .laptop-section {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .laptop-section {
        display: none;
    }

    .mobile-section {
        display: none;
    }

    .ipad-section {
        display: block;
    }
}
</style>
