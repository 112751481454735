<template>
    <nav class="navbar navbar-expand-lg sm-body-tertiary w-100"
        :class="{ 'red-background': hasScrolled, 'position-absolute': !hasScrolled }"
        style="padding-bottom: 0; background: transparent;">
        <div class="container">
            <div class="d-flex justify-content-between ">
                <a class="navbar-brand align-self-start" href="https://home.eurekali-point.com">
                    <template v-if="!hasScrolled">
                        <img class="navbar-brand align-self-start" src="../assets/img/logo_transparent.svg"
                            style="margin: -5px;">
                    </template>
                    <template v-else>
                        <img class="navbar-brand align-self-start" src="../assets/img/logo-white.svg"
                            style="margin: -5px;">
                    </template>
                </a>
                <button class="navbar-toggler align-self-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                    aria-label="Toggle navigation" style="border: 0px solid white; margin-bottom: 15px;">
                    <template v-if="!hasScrolled">
                        <img src="../assets/img/menu_toolbar.svg" style="transform: scale(1.5);">
                    </template>
                    <template v-else>
                        <img src="../assets/img/menu_toolbar_white.svg" style="transform: scale(1.5);">
                    </template>
                </button>
            </div>

            <div class=" collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav ms-auto ">
                    <li class="nav-item custom-nav-item">
                        <router-link style="font-size: 20px; font-weight: bold;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'white' }" class="nav-link active"
                            aria-current="page" to="/">{{ translate('home') }}</router-link>
                    </li>
                    <li class="nav-item dropdown custom-nav-item">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false" style="font-size: 20px; font-weight: bold;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'white' }">
                            {{ translate('new_shipment') }}
                        </a>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item" to="/b2b">{{ translate('business') }}</router-link>
                            </li>
                            <li><a class="dropdown-item" href="https://eurekali-point.com">{{
                                    translate('particular') }}</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/about" style="font-size: 20px; font-weight: bold;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'white' }" class="nav-link active"
                            aria-current="page">{{ translate('about_us') }}</router-link>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/contact" style="font-size: 20px; font-weight: bold;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'white' }" class="nav-link active"
                            aria-current="page">{{ translate('contact') }}</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto d-lg-inline-flex">
                    <li class="nav-item">
                        <div class="nav-link text-dark h5" style="position: relative;">
                            <button class="btn btn-danger custom-btn-new" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i class="fa fa-user" aria-hidden="true"></i> {{ translate('login') }}
                            </button>
                            <ul class="dropdown-menu">
                                <li><a href="https://www.eurekali.com/homepageForSegment?segment=b2b" class="dropdown-item">{{ translate('business')
                                        }}</a></li>
                                <li><a href="https://eurekali-point.com/login" class="dropdown-item">{{
                                        translate('coworking')
                                        }}</a></li>
                                <li><a href="https://eurekali-point.com/login" class="dropdown-item">{{
                                    translate('agent') }}</a></li>
                                <li><a href="https://eurekali-point.com/login" class="dropdown-item">{{
                                    translate('particular') }}</a></li>
                            </ul>
                        </div>
                    </li>

                    <li class="nav-item">
                        <div class="nav-link text-dark h5" style="position: relative;">
                            <button class="btn btn-danger custom-btn-new" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i class="fa fa-user-plus" aria-hidden="true"></i> {{ translate('sign_up') }}
                            </button>
                            <ul class="dropdown-menu">
                                <li><router-link to="/b2b" class="dropdown-item">{{ translate('business')
                                        }}</router-link></li>
                                <li><router-link to="/coworking" class="dropdown-item">{{ translate('coworking')
                                        }}</router-link>
                                </li>
                                <li><a href="https://eurekali-point.com/agent" class="dropdown-item">{{
                                        translate('agent')
                                        }}</a></li>
                                <li><a href="https://eurekali-point.com/signup"
                                        class="dropdown-item">{{ translate('particular')
                                        }}</a></li>
                            </ul>
                        </div>
                    </li>

                    <li class="nav-item dropdown" style="color: white;">
                        <select class="custom-select" name="lang" v-model="lang" @change="updateLang"
                            style="cursor: pointer; height: 50px; background-color: transparent; color: white; border: none; font-weight: bold;">
                            <option value="en" style="color: #E5097F;">EN</option>
                            <option value="fr" style="color: #E5097F;">FR</option>
                            <option value="nl" style="color: #E5097F;">NL</option>
                        </select>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
    mixins: [en, fr, nl],
    data() {
        return {
            orders: [],
            hasScrolled: false,
            lang: this.$store.state.lang,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);


        const language = navigator.language.split('-')[0];
        const supportedLanguages = ['en', 'fr', 'nl'];

        if (supportedLanguages.includes(language)) {
            this.lang = language;
        } else {
            this.lang = 'en';
        }

        this.updateLang()

    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        updateLang() {
            this.$store.commit('setLang', this.lang);
        },
        handleScroll() {
            this.hasScrolled = window.pageYOffset > 0;
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('removeAgent')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        }
    }
}
</script>




<style scoped>
.custom-nav-item:hover {
    text-decoration: underline #E5097F 4px;

}


.btn-login {
    color: white;
    background-color: #E5097F;
    font-weight: 600;
    border-color: #E5097F;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 128px;
    border: none;
    border-radius: 20px;
}

.btn-login:hover {
    color: white;
    background-color: #9B0656;
}

.btn-signup {
    color: white;
    background-color: #9B0656;
    font-weight: 600;
    border-color: #9B0656;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    width: 128px;
    border: none;
    border-radius: 20px;
}

.btn-signup:hover {
    color: white;
    background-color: #E5097F;
}

.btn-logout {
    color: white;
    background-color: #dc3545;
    font-weight: 600;
    border-color: #9B0656;
    padding: 8px 12px 8px 16px;
    gap: 8px;
    height: 40px;
    border: none;
    border-radius: 20px;
}

.btn-logout:hover {
    color: white;
    background-color: rgb(175, 12, 12);
}

.custom-btn-new {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #9B0656;
    border: none;
}

.custom-btn-new:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border: none;
}

.custom-btn-new:focus {
    background-color: #9B0656;
    border: none;
}

.custom-btn-new2 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #FF2400;
    border: none;
}

.custom-btn-new2:hover {
    background-color: #dc3545;
    border: none;
}

.custom-btn-new3 {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0px;
    border-radius: 30px;
    font-weight: 600;
    background-color: #9B0656;
    border: none;
}

.custom-btn-new3:hover {
    background-color: #FEEEF6;
    color: #E5097F;
    border: none;
}

.custom-select {
    border: none;
    font-weight: 600;
}

.red-background {
    background-color: #E5097F !important;
    color: white;
    padding-bottom: 0px;
    position: fixed;

}

.text-color-white {
    color: white;
}

.text-color-black {
    color: black;
}
</style>
